/** @jsx jsx */

import type { InputHTMLAttributes } from 'react';
import { forwardRef, useCallback, useState } from 'react';
import { jsx } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { useTheme } from '@balance-web/theme';
import { AlertCircleIcon, EyeIcon, EyeOffIcon } from '@balance-web/icon';
import { IconButton } from '@balance-web/button';

import { useInputStyles } from './utils';
import type { InputSizeType } from './types';
import { Adornment, AdornmentWrapper } from './Adornments';

const validTypes = {
  email: 'email',
  number: 'number',
  password: 'password',
  search: 'search',
  tel: 'tel',
  text: 'text',
  url: 'url',
};

type InputProps = InputHTMLAttributes<HTMLInputElement>;
export type TextInputProps = WithDataAttributeProp<
  {
    // FIXME: temp solution for invalid input w/o displaying an invalid message. we might need a "hideMessage" prop on `Field` to support assistive tech
    invalid?: boolean;
    onChange: NonNullable<InputProps['onChange']>;
    size?: InputSizeType;
    type?: keyof typeof validTypes;
    value: NonNullable<InputProps['value']>;
    /**
     * Hide the invalid adornment in cases where TextInput is overlaid as a toggle on top of another
     * input with an adornment such as DatePicker.
     */
    hideInvalidAdornment?: boolean;
  } & Omit<InputProps, 'onChange' | 'type' | 'size' | 'value'>
>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      invalid: invalidProp,
      size = 'medium',
      type = 'text',
      hideInvalidAdornment,
      data,
      ...props
    },
    ref
  ) => {
    // @ts-ignore Santise invalid dom attributes
    if ('blur' in props && typeof props.blur === 'function') {
      delete props.blur;
    }

    const { typography } = useTheme();
    const styles = useInputStyles({ size, shape: 'square' });
    const { invalid, ...a11yProps } = useFieldContext();
    const isInvalid = invalidProp ?? invalid;
    const [passwordType, setPasswordType] = useState<'password' | 'text'>(
      'password'
    );

    const dataAttributes = buildDataAttributes(data);

    const handleClick = useCallback(() => {
      setPasswordType((currentType) => {
        return currentType === 'password' ? 'text' : 'password';
      });
    }, [setPasswordType]);

    return (
      <AdornmentWrapper shape="round" size={size}>
        <input
          ref={ref}
          aria-invalid={isInvalid}
          css={styles}
          type={type === 'password' ? passwordType : type}
          {...a11yProps}
          {...props}
          {...dataAttributes}
        />
        {type === 'password' && (
          <Adornment align="right">
            <IconButton
              colorScheme="tertiary"
              icon={passwordType === 'text' ? EyeIcon : EyeOffIcon}
              label={
                passwordType === 'text' ? 'Hide password' : 'Show password'
              }
              size={size === 'large' ? 'medium' : size}
              variant="text"
              onClick={handleClick}
            />
          </Adornment>
        )}
        {!hideInvalidAdornment && isInvalid && type !== 'password' && (
          <Adornment
            align="right"
            css={{
              fontSize: typography.fontSize[size],
              pointerEvents: 'none',
            }}
          >
            <AlertCircleIcon
              color="critical"
              size={size === 'large' ? 'medium' : size}
            />
          </Adornment>
        )}
      </AdornmentWrapper>
    );
  }
);
