/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { Text } from '@balance-web/text';
import { forwardRefWithAs } from '@balance-web/utils';

type MessageProps = {
  /** The ID of the element. */
  id?: string;
};

export const FieldMessage = forwardRefWithAs<'div', MessageProps>(
  (props, ref) => {
    return (
      <Text
        ref={ref}
        color="critical"
        overflowStrategy="wrap"
        size="small"
        {...props}
      />
    );
  }
);
